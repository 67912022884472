import * as React from 'react';

import {
  AppPageLayout,
  AppPageLinks,
  contactTopics,
  HelpTopicContainer,
} from 'src/app.consumer/components';
import { Localized } from 'src/app/components';
import { AppProps, SeoObject } from 'src/app/types';

const seo: SeoObject = {
  title: 'Contact',
};

const ContactPage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={<Localized dictKey={'staticLocales:pages./contact.subtitle'} />}
      title={<Localized dictKey={'staticLocales:pages./contact.link label'} />}
      {...{ ...props, seo }}
    >
      <div>
        {contactTopics?.map((topic) => {
          return (
            <div key={topic.id}>
              <HelpTopicContainer dictKey={'shop:/contact.topic'} topicData={topic} />
            </div>
          );
        }) || null}
      </div>
    </AppPageLayout>
  );
};

export default ContactPage;
